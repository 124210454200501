<template>
  <div
    ref="container"
    class="return-method__radio-container"
    :class="{ 'selected': selected === option.name, 'single': isSingle }"
    @click="$emit('change', option)"
  >
    <radio-input
      ref="radio"
      modelValue="selected"
      class="return-method__radio-input"
      :class="{ 'selected': selected === option.name, 'single': isSingle }"
      :value="option.name"
      :name="name"
      :disabled="disabled"
      @focus="updateFocus"
      @focusout="removeFocus"
      @blur="removeFocus"
      @change="$emit('change', option)"
      @update:modelValue="$emit('update:selected', $event)"
    >
      <div class="return-method__content-row">
        <div class="return-method__icon">
          <img
            class="return-method__icon-img"
            :src="option.logo"
            :class="{ 'return-method__icon-img--disabled': disabled }"
          />
        </div>
        <span class="return-method__radio-content">
          <div class="return-method__heading">
            <base-text
              class="return-method__heading-text"
              type="body"
            >
              <render-content :data="{carrier: option.name}">
                {{ moduleContent.heading }}
              </render-content>
            </base-text>
            <base-tooltip
              v-if="showLeafIcon"
              class="summary-section__description"
              placement="bottom"
            >
              <img
                class="return-method__leaf"
                src="/img/leaf.svg"
              />
              <template #content>
                <div class="sustainable-tooltip">
                  <render-content>
                    {{ $content.moduleSustainableBanner.sustainableDescription }}
                  </render-content>
                </div>
              </template>
            </base-tooltip>
          </div>
          <base-text
            type="body-2"
            class="return-method__subheading"
          >
            <render-content :data="{carrier: option.name}">
              {{ moduleContent.description }}
            </render-content>
          </base-text>
        </span>
      </div>
    </radio-input>
    <transition-expand>
      <div
        v-if="selected === option.name"
        class="return-method-container"
      >
        <div
          class="return-method__option"
          :class="{ 'return-method__option--disabled': disabled }"
        >
          <base-text
            v-if="shouldShowLabelChoices"
            type="body-2"
            class="return-method-prompt"
          >
            <render-content :data="{carrier: option.name}">
              {{ moduleContent.prompt }}
            </render-content>
          </base-text>

          <base-text
            v-if="shouldShowLabelChoices"
            type="body-2"
            class="return-method-legal"
          >
            {{ moduleContent.body }}
          </base-text>

          <base-button
            v-if="option.locationLink"
            class="all-locations-btn"
            size="small"
            type="secondary"
            target="_blank"
            :to="option.locationLink"
            @click.stop
          >
            <span class="icon-label">
              <render-content :data="{carrier: option.name}">
                {{ moduleContent.viewLocationsBtn }}
              </render-content>
              <base-icon
                class="external-icon"
                name="external-link"
              />
            </span>
          </base-button>

          <div
            v-if="option.options.length > 1"
            class="label-choice__radio-container"
          >
            <hr class="label-choice__hr" />
            <div
              v-for="option in option.options"
              :key="option.key"
              class="label-choice__radio"
              @click="handlePickupOptionSelect(option)"
            >
              <radio-input
                v-model="returnOptionSelected"
                class="label-choice__radio-option"
                :value="option.key"
                @change="handlePickupOptionSelect(option)"
              >
                <base-text
                  class="label-choice__radio-option-heading"
                  type="body"
                  data-testid="qr-code-radio-button"
                >
                  <render-content :data="{carrier: carrier}">
                    {{ moduleContent[option.heading] }}
                  </render-content>
                </base-text>

                <base-text
                  class="label-choice__radio-option-copy"
                  type="body"
                  data-testid="qr-code-radio-button"
                >
                  <render-content :data="{carrier: carrier}">
                    {{ moduleContent[option.copy] }}
                  </render-content>
                </base-text>
              </radio-input>
            </div>
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script setup>
import {
  BaseText,
  RadioInput,
  BaseTooltip,
  BaseIcon,
  BaseButton
} from '@loophq/design-system';
import RenderContent from '@/components/renderers/RenderContent.vue';
import firstMile from '@/js/constants/firstMile';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  selected: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true,
  },
  isSingle: {
    type: Boolean,
    default: false,
  }
});

defineEmits(['change', 'update:selected']);
const store = useStore();

const content = computed(() => store.getters.content);
const settings = computed(() => store.getters.settings || {
  sustainabilityBannerEnabled: false
});

const shouldShowLabelChoices = computed(() => {
  return props.option.options && props.option.options.length > 1;
});
const carrier = computed(() => {
  return props.option.name;
});

const container = ref(null);
const returnOptionSelected = ref('');

const toTitleCase = (str) => {
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');
};

const moduleContent = computed(() => {
  const baseModule = 'moduleReturnMethodSelector';
  const slug = toTitleCase(props.option.slug);

  // Try slug-specific content first
  if (content.value[`${baseModule}${slug}`]) {
    return content.value[`${baseModule}${slug}`];
  }

  // If there is not a carrier specific module, fall back to generic content
  return content.value[`${baseModule}Generic`];
});

const showLeafIcon = computed(() => {
  return settings.value.sustainabilityBannerEnabled && 
    Object.keys(firstMile.SUSTAINABLE_RETURN_METHODS).includes(props.option.name);
});

const updateFocus = () => {
  container.value?.classList.add('focused');
};

const removeFocus = () => {
  container.value?.classList.remove('focused');
};

const handlePickupOptionSelect = (option) => {
  returnOptionSelected.value = option.key;
  store.dispatch('firstMile/setReturnMethod', {
    ...props.option,
    returnOption: option.key,
  });
};

onMounted(() => {
  if (props.option.options.length === 1) {
    returnOptionSelected.value = props.option.options[0].key;
    handlePickupOptionSelect(props.option.options[0]);
  }
});
</script>

<style lang="scss" scoped>
$icon-size: 48px;

.all-locations-btn {
  margin-top: 0;
}

.distance {
  margin-bottom: 12px;
}

.heading {
  display: inline-block;
  color: var(--grey-800);
  font-weight: 500;
  padding-right: var(--spacing-200);
}

.link {
  color: inherit !important;
  font-weight: normal;
  display: inline-block !important;
}

.location-info {
  margin: var(--spacing-300) 0;
}

.show-more {
  margin: var(--spacing-400) 0 0;
  display: flex !important;
}

.show-more-label {
  display: flex;
  color: var(--primary-color);
}

.location-address {
  text-decoration: underline;
  text-align: left;
}

.return-method-container {
  background-color: white;
  padding: var(--spacing-400);
}

.external-icon {
  height: 16px;
  margin-left: var(--spacing-200);
}

.icon-label {
  display: flex;
  align-items: center;
  color: #41454a;
}

.link .icon-label {
  text-decoration: underline;
}

.link-icon {
  height: 12px;
}

.return-method-legal {
  color: var(--grey-800);
  margin-bottom: 12px;
}

.label-choice__hr {
  border: 1px solid var(--grey-200);
  margin: var(--spacing-400) 0;
}

.label-choice__radio {
  & + & {
    margin-top: var(--spacing-200);
  }

  &-option {
    display: flex;
    align-items: center;
    padding: var(--spacing-200) 0;

    &-heading {
      color: var(--heading-color);
      font-weight: 600;
    }

    &.disabled {
      &:deep(.radio-input__input) {
        border: none;
        background-color: var(--grey-300);
      }

      &:deep(.radio-input__label) > p {
        color: var(--grey-600);
      }
    }
  }

  &:deep(.radio-input__label) {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 var(--spacing-300);
  }
}

/* Second block styles */
.return-method {
  &__radio-container {
    transition: 0.4s ease border-color, 0.4s ease box-shadow;

    &:deep(.radio-input__label) {
      flex: 1;

      &::before {
        display: none !important;
      }
    }

    &.single {
      &:deep(.radio-input__label) {
        cursor: default;
      }
    }

    &:deep(.radio-input__input.focus-visible+.radio-input__label) {
      &::after {
        display: none !important;
      }
    }

    &:deep(.radio-input__input) {
      position: relative !important;
      height: var(--spacing-400);
      min-width: var(--spacing-400);
      border: 2px solid var(--grey-600);
      margin: 0;

      &.single {
        display: none;
      }
    }

    &:deep(.base-tooltip__content) {
      max-width: none;
    }

    &.focused,
    &:focus-visible {
      outline: 4px solid var(--theme-outline-color);
      outline-offset: 1px;
    }

    &:hover {
      background: #f7f8f9;
    }

    &:active {
      border: 1px solid #c9cbcf;
    }

    &.selected {
      background: #f7f8f9;

      &.return-method__radio-input {
        box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
      }
    }
  }

  &__radio-input {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: var(--spacing-400);

    &.selected {
      position: relative;
      border-bottom: 1px solid var(--grey-200);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 10%);
    }

    &.single {
      border: 0;
      box-shadow: none;
      background: white;
      padding: 0;
    }
  }

  &__icon-img {
    height: $icon-size;
    width: $icon-size;
    margin-right: var(--spacing-300);
    background: white;
    border-radius: var(--spacing-300);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &__radio-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
  }

  &__option {
    opacity: 1;
    transition: opacity var(--easing-quick);
    background: white;

    &--disabled {
      opacity: 0.3;
    }
  }

  &__content-row {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &__fee {
    display: none;
  }

  &__fee-small {
    display: block;
  }

  &__fee-free {
    margin: 0 8px 0 var(--spacing-300);
    color: var(--grey-600);
    text-align: right;

    &.single {
      display: none;
    }
  }

  &__content-row {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &__heading {
    display: flex;

    &-text {
      color: var(--heading-color);
      font-weight: bold;
    }
  }

  &__leaf {
    height: 15px;
    margin-left: var(--spacing-200);
  }

  &__subheading {
    color: var(--heading-color);
    margin-top: var(--spacing-100);
  }
}

/* Media Queries */
@media screen and (max-width: $break-small) {
  .distance {
    display: block;
  }

  .return-method__fee {
    display: none;
  }

  .return-method__fee-small {
    display: block;
  }
}
</style>
